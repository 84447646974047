import copyIcon from 'assets/copy-svgrepo-com.svg';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useEffect, useState } from 'react';
import { useGetNairaWalletMutation } from 'services/wallet';

const BankDetails = () => {
  const [getNairaWallet] = useGetNairaWalletMutation();
  const [accountData, setAccountData] = useState({
    accountName: '',
    accountNumber: '',
  });

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await getNairaWallet({}).unwrap();
        setAccountData({
          accountName: response?.data?.account_name || 'N/A',
          accountNumber: response?.data?.account_number || 'N/A',
        });
      } catch (error) {
        // console.error('Error fetching bank details:', error);
      }
    };
    fetchBankDetails();
  }, [getNairaWallet]);

  const handleCopy = useCopyToClipboard(accountData.accountNumber);

  return (
    <div className="flex flex-col gap-3 lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px] w-full">
      <div className="flex justify-between md:items-center">
        <h1 className="font-[900] text-[18px] lg:text-[24px] text-[#343434] leading-[40px]">
          Bank Details
        </h1>
      </div>
      <div className="flex lg:flex-col gap-3 flex-col-reverse">
        <div className="flex justify-between gap-[30px] md:gap-0">
          <div className="flex flex-col gap-3 w-[70%] whitespace-nowrap md:whitespace-normal">
            <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Account Name</p>
            <h1 className="text-[#101828] font-[700] text-[10px] md:text-[14px] leading-[20px]">
              {accountData.accountName}
            </h1>
          </div>
          <div className="hidden lg:flex flex-col gap-3 w-[30%]">
            <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Account Number</p>
            <div className="flex items-center gap-2">
              <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                {accountData.accountNumber}
              </h1>
              <button onClick={handleCopy}>
                <img src={copyIcon} alt={copyIcon} />
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-[20px]">
          <div className="flex flex-col gap-3">
            <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Bank Name</p>
            <h1 className="text-[#101828] font-[700] text-[10px] md:text-[14px] leading-[20px]">
              Safe Haven Microfinance Bank
            </h1>
          </div>
          <div className="lg:hidden flex flex-col gap-3 lg:w-[30%]">
            <p className="text-[#A7A7A7] font-[500] text-[14px] leading-[18px]">Account Number</p>
            <div className="flex items-center gap-2">
              <h1 className="text-[#101828] font-[700] text-[14px] leading-[20px]">
                {accountData.accountNumber}
              </h1>
              <button onClick={handleCopy}>
                <img src={copyIcon} alt={copyIcon} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
