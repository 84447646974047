import { DownOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left.svg';
import rightArrowIcon from 'assets/Arrow - Right.svg';
import ngnIcon from 'assets/flag-for-nigeria-svgrepo-com 1.svg';
import debitIcon from 'assets/Group 7.svg';
import creditIcon from 'assets/Group 107.svg';
import usdIcon from 'assets/Group 1171275979.svg';
import sgdIcon from 'assets/Group 1171275982.svg';
import displayIcon from 'assets/Group 1171275983.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import bitIcon from 'assets/image 3.svg';
import cnyIcon from 'assets/Mask group.svg';
import unLockIcon from 'assets/Unlock.svg';
import plus1Icon from 'assets/Vector (1).svg';
import plusIcon from 'assets/Vector.svg';
import AddFund from 'components/userComponent/AddFundModal';
import Convertion from 'components/userComponent/ConvertionModal';
import SendMoney from 'components/userComponent/SendMoneyModal';
import SideBar from 'components/userComponent/SideBar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KYC_ROUTE, REQUIRED_ROUTE } from 'routes/path';
import { useGetUserMutation } from 'services/auth';
import { useGetBusinessMutation } from 'services/business';
import { useGetKYBMutation } from 'services/kyb';
import { useGetTransactionMutation } from 'services/Transaction';
import { useGetAllWalletMutation } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { setUser } from 'store/auth';
import { setBusiness } from 'store/business';
import { setKYB } from 'store/kyb';
import { User } from 'types/auth';
import { AccountBalances, Transaction, WalletResponse } from 'types/Transaction';

const Dashboard = () => {
  const [getUser] = useGetUserMutation();
  const [getBusiness] = useGetBusinessMutation();
  const [getKYB] = useGetKYBMutation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const [kybStatus, setKybStatus] = useState('');
  const [getWalletById] = useGetAllWalletMutation();
  const [accountBalances, setAccountBalances] = useState<AccountBalances>({});
  const [getTransaction] = useGetTransactionMutation();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState('NGN');
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);

  const accountData = [
    { icon: usdIcon, currency: 'USD' },
    { icon: cnyIcon, currency: 'CNY' },
    { icon: ngnIcon, currency: 'NGN' },
    { icon: sgdIcon, currency: 'SGD' },
    { icon: hkdIcon, currency: 'HKD' },
  ];

  type ModalType = 'fundAccount' | 'send' | 'convert';

  const [modalState, setModalState] = useState<{ [key in ModalType]?: boolean }>({
    fundAccount: false,
    send: false,
    convert: false,
  });

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstItem, indexOfLastItem);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    getUser({})
      .unwrap()
      .then((response: { data: User }) => {
        dispatch(setUser(response.data));
        setIsDataFetched(true);
      });
  }, [dispatch, getUser]);

  useEffect(() => {
    getBusiness({})
      .unwrap()
      .then((response) => {
        dispatch(setBusiness(response.data));
        getUserKyb();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getBusiness, getKYB]);

  useEffect(() => {
    const fetchWalletBalances = async () => {
      try {
        const walletResponse: WalletResponse = await getWalletById({}).unwrap();
        const balances: AccountBalances = {};

        walletResponse.data.forEach((wallet) => {
          balances[wallet.currency] = parseFloat(wallet.available).toFixed(2);
        });

        setAccountBalances(balances);
      } catch (error) {
        // console.error('Failed to fetch wallet balances:', error);
      }
    };

    fetchWalletBalances();
  }, [getWalletById]);

  useEffect(() => {
    const fetchTransactionsData = async () => {
      try {
        const transactionResponse = await getTransaction({}).unwrap();
        setTransactions(transactionResponse.data);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchTransactionsData();
  }, [getTransaction]);

  const getUserKyb = () => {
    getKYB({})
      .unwrap()
      .then((response) => {
        dispatch(setKYB(response.data));
        setKybStatus(response.data.questionaire.status);
        setIsDataFetched(true);
      });
  };

  const toggleModal = (type: ModalType, isOpen: boolean) => {
    setModalState((prevState) => ({ ...prevState, [type]: isOpen }));
  };

  const showModal = (type: ModalType) => toggleModal(type, true);
  const handleCancel = (type: ModalType) => toggleModal(type, false);

  const availableAccounts = accountData.filter((account) => accountBalances[account.currency]);

  const handleMenuClick = ({ key }: { key: string }) => {
    setSelectedCurrency(key);
  };

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible((prevState) => !prevState);
  };

  const items = availableAccounts.map((account) => ({
    key: account.currency,
    label: (
      <div className="flex items-center gap-2">
        <img src={account.icon} alt={account.currency} className="w-[18px] h-[18px]" />
        {account.currency}
      </div>
    ),
  }));

  function formatBalance(balance: number): string {
    const absBalance = Math.abs(balance);

    let formattedBalance = '';
    if (absBalance >= 1e9) formattedBalance = (absBalance / 1e9).toFixed(1) + 'B';
    else if (absBalance >= 1e6) formattedBalance = (absBalance / 1e6).toFixed(1) + 'M';
    else if (absBalance >= 1e3) formattedBalance = (absBalance / 1e3).toFixed(1) + 'K';
    else
      formattedBalance = absBalance.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    return balance < 0 ? `-${formattedBalance}` : formattedBalance;
  }

  function getFormattedBalance(currency: string): string {
    const balance = parseFloat(accountBalances[currency] || '0');
    return formatBalance(balance);
  }

  return (
    <SideBar isKycVerified={user?.is_bvn_verified && user?.is_kyb_verified}>
      <div className="flex flex-col gap-4 lg:gap-10">
        <h1 className="text-[#343434] font-[700] text-[15px] md:text-[24px] leading-[40px]">
          Welcome {user.first_name} 👋🏽,
        </h1>
        {user?.is_bvn_verified && user?.is_kyb_verified && (
          <div className="flex md:flex-row flex-col md:items-center md:gap-10">
            <div className="flex items-center gap-2">
              <button onClick={toggleBalanceVisibility} className="relative bottom-3 md:bottom-0">
                {isBalanceVisible ? (
                  <EyeOutlined className="text-gray-500" />
                ) : (
                  <EyeInvisibleOutlined className="text-gray-500" />
                )}
              </button>
              <div className="flex flex-col gap-2">
                <p className="text-[#666666] font-[500] text-[14px] leading-[18.9px]">
                  Account Balance
                </p>
                <div className="flex items-center xl:gap-4 bottom-4 lg:bottom-0 relative md:gap-2 lg:gap-0">
                  <p className="font-[900] leading-[40px] text-[18px] xl:text-[28px]">
                    {isBalanceVisible
                      ? `${new Intl.NumberFormat().format(
                          Number(accountBalances[selectedCurrency])
                        )} ${selectedCurrency}`
                      : '*******'}
                  </p>
                  <Dropdown
                    overlay={<Menu onClick={handleMenuClick} items={items} />}
                    trigger={['hover']}
                  >
                    <button
                      onClick={(e) => e.preventDefault()}
                      className="relative bottom-1 md:bottom-2 left-1 md:left-0"
                    >
                      <Space>
                        <DownOutlined />
                      </Space>
                    </button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-[8px] xl:gap-[16px] font-[700] text-[12px] md:text-[14px] leading-[18.86px] text-[#1174ED]">
              <button
                onClick={() => {
                  showModal('fundAccount');
                }}
                className="flex items-center gap-2 text-[#FFFFFF] bg-[#1174ED] py-[7px] px-[10px] md:px-[20px] rounded-[10px]"
              >
                <img src={plusIcon} alt={plusIcon} />
                Fund Account
              </button>
              <AddFund
                openModal={!!modalState.fundAccount}
                close={() => handleCancel('fundAccount')}
              />
              <button
                onClick={() => {
                  showModal('send');
                }}
                className="flex items-center gap-2 border border-[#1174ED] py-[7px] px-[10px] md:px-[20px] rounded-[10px]"
              >
                <img src={plus1Icon} alt={plus1Icon} />
                Send
              </button>
              <SendMoney
                openMoneyModal={!!modalState.send}
                closeModaL={() => handleCancel('send')}
              />
              <button
                onClick={() => {
                  showModal('convert');
                }}
                className="flex items-center gap-2 border border-[#1174ED] py-[7px] px-[10px] md:px-[20px] rounded-[10px]"
              >
                <img src={plus1Icon} alt={plus1Icon} />
                Convert
              </button>
              <Convertion
                isOpen={!!modalState.convert}
                handleCancel={() => handleCancel('convert')}
              />
            </div>
          </div>
        )}

        {isDataFetched && (
          <div>
            {!user?.is_bvn_verified && (
              <div className="shaw border border-[#1174ED] md:h-[90px] py-[10px] rounded-[10px] bg-[#F5F9FF] flex md:flex-row flex-col gap-2 justify-between items-center px-[8px] md:px-[30px]">
                <div className="flex items-center gap-3">
                  <img src={unLockIcon} alt={unLockIcon} className="h-[20px] md:h-[100%]" />
                  <div>
                    <h1 className="text-[#27303A] font-[700] text-[16px] leading-[22.4px]">
                      Activate your Account
                    </h1>
                    <p className="text-[#666666] font-[500] text-[14px] leading-[18.9px]">
                      Provide your basic personal information to activate your account.
                    </p>
                  </div>
                </div>
                <Link to={KYC_ROUTE}>
                  <button className="text-[#fff] py-[7px] px-[20px] font-[700] text-[12.57px] leading-[18.86px] bg-[#1174ED] rounded-[10px]">
                    Complete your KYC
                  </button>
                </Link>
              </div>
            )}

            {!user?.is_kyb_verified && user?.is_bvn_verified && (
              <div className="shaw border border-[#1174ED] md:h-[90px] py-[10px] rounded-[10px] bg-[#F5F9FF] flex md:flex-row flex-col gap-2 justify-between items-center px-[8px] md:px-[30px]">
                <div className="flex items-center gap-3">
                  <img src={unLockIcon} alt={unLockIcon} className="h-[20px] md:h-[100%]" />
                  <div>
                    <h1 className="text-[#27303A] font-[700] text-[16px] leading-[22.4px]">
                      Business Verification
                    </h1>
                    <p className="text-[#666666] font-[500] text-[14px] leading-[18.9px]">
                      Provide required information about your business to access the full features
                      of Astra for Business.
                    </p>
                  </div>
                </div>
                {kybStatus === 'Processing' ? (
                  <button
                    className="text-[#fff] py-[7px] px-[20px] font-[700] text-[12.57px] leading-[18.86px] bg-[#92B9F7] rounded-[10px]"
                    disabled
                  >
                    Processing
                  </button>
                ) : (
                  <Link to={REQUIRED_ROUTE}>
                    <button className="text-[#fff] py-[7px] px-[20px] font-[700] text-[12.57px] leading-[18.86px] bg-[#1174ED] rounded-[10px]">
                      Complete your KYB
                    </button>
                  </Link>
                )}
              </div>
            )}
          </div>
        )}

        {!user?.is_kyb_verified && user?.is_bvn_verified && (
          <div className="flex justify-center pb-[20rem]">
            <div className="flex flex-col items-center gap-4 pt-14">
              <img src={displayIcon} alt={displayIcon} />
              <p className="text-[#D6DCE8] leading-[40px] text-[20px] font-[400]">
                There is nothing to see here
              </p>
            </div>
          </div>
        )}
        {!user?.is_bvn_verified && (
          <div className="flex justify-center pb-[20rem]">
            <div className="flex flex-col items-center gap-4 pt-14">
              <img src={displayIcon} alt={displayIcon} />
              <p className="text-[#D6DCE8] leading-[40px] text-[20px] font-[400]">
                There is nothing to see here
              </p>
            </div>
          </div>
        )}

        {user?.is_bvn_verified && user?.is_kyb_verified && (
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col md:gap-4">
              <div className="flex justify-between items-center">
                <h1 className="font-[900] text-[18px] md:text-[24px] leading-[40px] text-[#343434]">
                  Accounts
                </h1>
              </div>
              <div
                className="flex gap-4 overflow-x-scroll scrollbar-hide"
                style={{
                  WebkitOverflowScrolling: 'touch',
                }}
              >
                {availableAccounts.map((account, index) => (
                  <div
                    key={index}
                    className="h-[100px] md:h-[150px] rounded-[10px] bg-[#F5F5F5] w-[264px] p-[10px] md:p-[15px] flex flex-col justify-between"
                  >
                    <div className="font-[900] text-[10px] md:text-[16px] leading-[18px] flex items-center gap-2">
                      <img
                        src={account.icon}
                        alt={account.currency}
                        className="md:w-[42px] md:h-[42px] h-[30px] w-[30px]"
                      />
                      <p>{account.currency}</p>
                    </div>
                    <div className="flex justify-between gap-1">
                      <div>
                        <p className="text-[#666666] font-[400] text-[8px] md:text-[10px] md:leading-[13.5px]">
                          Local account
                        </p>
                        <p
                          className="text-[#343434] font-[700] text-[10px] md:text-[14px] leading-[18px]"
                          style={{ maxWidth: '100px' }}
                        >
                          {accountBalances[account.currency]
                            ? getFormattedBalance(account.currency)
                            : `${account.currency} not found`}
                        </p>
                      </div>
                      <div>
                        <p className="text-[#666666] font-[400] text-[8px] md:text-[10px] md:leading-[13.5px]">
                          Global account
                        </p>
                        <p
                          className="text-[#343434] font-[700] text-[10px] md:text-[14px] leading-[18px]"
                          style={{ maxWidth: '100px' }}
                        >
                          {accountBalances[account.currency]
                            ? getFormattedBalance(account.currency)
                            : `${account.currency} not found`}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex md:flex-col lg:flex-row justify-between gap-6">
              <div className="w-[554px] flex flex-col gap-3">
                <div className="flex justify-between items-center">
                  <h1 className="font-[900] text-[18px] md:text-[24px] leading-[40px] text-[#343434]">
                    Transactions
                  </h1>
                  {transactions.length > itemsPerPage && (
                    <div className="text-[#1174ED] font-[700] text-[14px] leading-[18.9px] flex items-center gap-2">
                      <button
                        className="mx-1 cursor-pointer"
                        onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <img src={leftArrowIcon} alt={leftArrowIcon} />
                      </button>

                      <button
                        className="mx-1 cursor-pointer"
                        onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                        disabled={indexOfLastItem >= transactions.length}
                      >
                        <img src={rightArrowIcon} alt={rightArrowIcon} />
                      </button>
                    </div>
                  )}
                </div>
                <div className="trans_shadow flex flex-col gap-4 bg-[#fff] xl:p-[20px] p-[10px] rounded-[10px]">
                  {currentTransactions.length > 0 ? (
                    currentTransactions.map((transaction, index) => (
                      <div
                        key={index}
                        className="flex justify-between border-b-[1px] pb-3 border-b-[#D0D0D033]"
                      >
                        <div className="flex items-center gap-2">
                          <img
                            src={
                              ['Initiated', 'Failed'].includes(transaction.status)
                                ? debitIcon
                                : creditIcon
                            }
                            alt="transactionIcon"
                            className="h-[30px] md:h-[100%]"
                          />
                          <div className="flex flex-col gap-1">
                            <h1 className="font-[700] text-[14px] md:text-[16px] leading-[21.6px]">
                              {transaction.type}
                            </h1>
                            <p className="text-[#666666] font-[500] text-[10px] md:text-[14px] leading-[18.9px]">
                              {new Date(transaction.created_at).toLocaleDateString()} |
                              {new Date(transaction.created_at).toLocaleTimeString()}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col gap-1 items-center">
                          <h1 className="font-[700] text-[14px] md:text-[16px] leading-[21.6px]">
                            {Number(transaction.amount.value).toLocaleString()}
                            {transaction.amount.currency}
                          </h1>
                          <p
                            className={`font-[500] text-[10px] md:text-[14px] leading-[18.9px] md:py-[2.37px] py-[1px] px-[7px] md:px-[7.1px] rounded-[5px] ${
                              transaction.status === 'Initiated' || transaction.status === 'Failed'
                                ? 'text-red-600 bg-red-100'
                                : transaction.status === 'Processing'
                                ? 'text-[#FBB040] bg-[#FBB0401A]'
                                : 'text-green-600 bg-green-100'
                            }`}
                          >
                            {transaction.status === 'Completed'
                              ? 'Completed'
                              : transaction.status === 'Processing'
                              ? 'Processing'
                              : transaction.status === 'Failed'
                              ? 'Failed'
                              : 'Initiated'}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No transactions available</p>
                  )}
                </div>
              </div>
              <div className="md:flex flex-col gap-4 hidden">
                <img src={bitIcon} alt={bitIcon} />
              </div>
            </div>
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default Dashboard;
