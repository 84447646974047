const Info = () => {
  return (
    <div className="box flex flex-col gap-3 border-[1px] border-[#1174ED] bg-[#F5F9FF] rounded-[10px] p-[15px] h-[100%]">
      <div className="flex gap-2">
        <div>
          <div className="h-[21px] w-[21px] bg-[#1174ED] rounded-[6px] font-[700] flex justify-center items-center text-[#fff] relative top-4">
            i
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="text-[#27303A] font-[700] text-[16px] leading-[22.4px]">
            Important Notice- Transfer instructions.
          </h1>
          <div className="text-[#666666] font-[500] text-[13px] leading-[19px] hidden lg:flex flex-col gap-1">
            <p>You can fund your accounts by bank transfers only</p>
            <p>The account above is created to add funds to your naira.</p>
            <p>
              Once you make payments to this account, it will reflect in your wallet and enable you
              to manage your funds
            </p>
          </div>
          <div className="text-[#666666] font-[500] text-[13px] leading-[19px] block lg:hidden">
            You can fund your accounts by bank transfers only The account above is created to add
            funds to your naira. Once you make payments to this account, it will reflect in your
            wallet and enable you to manage your funds
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
