import { Spin, Table, TableColumnsType } from 'antd';
import leftArrowIcon from 'assets/Arrow - Left Square.svg';
import rightArrowIcon from 'assets/Arrow - Right Square.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import searchIcon from 'assets/search.svg';
import SideBar from 'components/userComponent/SideBar';
import TransactionModal from 'components/userComponent/TransactionModal';
import useTableScroll from 'components/useTableScroll';
import { useEffect, useState } from 'react';
import Protected from 'routes/Protected';
import { useGetTransactionMutation } from 'services/Transaction';
import { useAppDispatch } from 'store';
import { setTransaction } from 'store/Transaction';
import { DataType } from 'types/Transaction';

const Transaction = () => {
  const [getTransaction] = useGetTransactionMutation();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<DataType | null>(null);
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [activeFilter, setActiveFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const scrollX = useTableScroll();
  const [isRotated, setIsRotated] = useState(false);
  const recordsPerPage = 10;

  const handleRefresh = () => {
    setIsRotated(!isRotated);
    setLoading(true);
    getTransaction({})
      .unwrap()
      .then((response) => {
        const transactions = response.data.map(
          (transaction: {
            id: string;
            created_at: string | number | Date;
            reference: string;
            type: string;
            status: string;
            amount: {
              value: string;
              currency: string;
            };
            description: string;
            fees: { value: string };
          }) => {
            const formatTo12Digit = (value: string) => {
              return value.slice(0, 12).padEnd(12, '0');
            };

            const formatAmount = (value: string) => {
              return new Intl.NumberFormat('en-US').format(parseFloat(value));
            };

            return {
              key: transaction.id,
              date: new Date(transaction.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }),
              transaction_id: formatTo12Digit(transaction.reference),
              type: transaction.type,
              status: transaction.status,
              amount: `${transaction.amount.currency} ${formatAmount(transaction.amount.value)}`,
              reference: formatTo12Digit(transaction.reference),
              description: transaction.description,
              fees: formatAmount(transaction.fees.value),
            };
          }
        );
        dispatch(setTransaction(transactions));
        setData(transactions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Completed':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847', width: '75%' };
      case 'Processing':
        return {
          backgroundColor: '#FBB0401A',
          color: '#FBB040',
          dotColor: '#FBB040',
          width: '78%',
        };
      case 'Initiated':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '75%',
        };
      case 'Abandoned':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '75%',
        };
      case 'Failed':
        return {
          backgroundColor: '#DC44371A',
          color: '#DC4437',
          dotColor: '#DC4437',
          width: '68%',
        };
      default:
        return {};
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        const { backgroundColor, color, dotColor, width } = getStatusStyle(text);
        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: '0px 0  0 10px',
              borderRadius: '16px',
              display: 'flex',
              gap: '5px',
              paddingLeft: '15px',
              alignItems: 'center',
              width: width,
              height: '22px',
              fontSize: '12px',
            }}
          >
            <div
              style={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: dotColor,
              }}
            ></div>
            {text}
          </div>
        );
      },
    },
  ];

  const showModal = (transaction: DataType) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFilterChange = (filter: string) => {
    setActiveFilter(filter);
  };

  const filteredData = data.filter((item) => {
    const matchesSearchText =
      item.type.toLowerCase().includes(searchText.toLowerCase()) ||
      item.status.toLowerCase().includes(searchText.toLowerCase());

    const matchesFilter =
      activeFilter === 'All' ||
      (activeFilter === 'Completed' && item.status === 'Completed') ||
      (activeFilter === 'Failed' && item.status === 'Failed') ||
      (activeFilter === 'Initiated' && item.status === 'Initiated') ||
      (activeFilter === 'Processing' && item.status === 'Processing');

    return matchesSearchText && matchesFilter;
  });

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filters = [
    { label: 'All', width: '44px' },
    { label: 'Initiated', width: '79px' },
    { label: 'Processing', width: '75px' },
    { label: 'Failed', width: '50px' },
    { label: 'Completed', width: '80px' },
  ];

  return (
    <Protected>
      <SideBar isKycVerified={true}>
        <div className="h-[100vh]">
          <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px] w-[100%]">
            <div className="flex justify-between w-[100%] border-b border-b-[#EAECF0] p-[15px]">
              <div className="font-[900] text-[#343434] text-[18px] lg:text-[24px] leading-[40px] w-[20%]">
                <h1>Transactions</h1>
              </div>
              <div className="hidden md:flex justify-between w-[70%] lg:w-[80%]">
                <div className="hidden lg:flex items-center gap-2 text-[#344054] font-[700] text-[12.57px] leading-[18.86px]">
                  {filters.map(({ label, width }) => (
                    <button
                      key={label}
                      className={`border border-[#8894EC] py-[7px] px-[20px] h-[33px] flex justify-center items-center rounded-[30px] ${
                        activeFilter === label ? 'bg-[#8894EC33] text-[#8894EC]' : 'text-[#8894EC]'
                      }`}
                      style={{ width }}
                      onClick={() => handleFilterChange(label)}
                    >
                      {label}
                    </button>
                  ))}
                </div>
                <div className="input flex items-center">
                  <img
                    src={searchIcon}
                    alt={searchIcon}
                    className="relative left-[30px] -ml-[15px]"
                  />
                  <input
                    type="text"
                    placeholder="Search transactions"
                    value={searchText}
                    onChange={handleSearch}
                    className="bg-[#F5F5F5] pl-[35px] rounded-[10px] h-[40px] py-[16.21px] w-[250px]"
                  />
                </div>
                <button
                  onClick={handleRefresh}
                  className="flex items-center font-[500] text-[14px] leading-[20px]"
                >
                  <img
                    src={refreshIcon}
                    alt={refreshIcon}
                    className={`transition-transform duration-500 ease-in-out ${
                      isRotated ? 'rotate-360' : ''
                    }`}
                  />
                  <p>Refresh</p>
                </button>
              </div>
            </div>

            {loading ? (
              <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
                <Spin size="large" />
              </div>
            ) : (
              <div className="flex flex-col w-full overflow-auto">
                <Table
                  columns={columns}
                  dataSource={currentRecords}
                  className="table cursor-pointer"
                  id="table"
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        showModal(record);
                      },
                    };
                  }}
                  scroll={{
                    x: scrollX,
                  }}
                />
                {filteredData.length > recordsPerPage && (
                  <div className="text-[#1174ED] font-[700] text-[14px] leading-[18.9px] flex justify-end py-[10px] pr-[20px] lg:pr-[50px] items-center gap-2">
                    <button className="mx-1 cursor-pointer" onClick={prevPage}>
                      <img src={leftArrowIcon} alt={leftArrowIcon} />
                    </button>
                    <button className="mx-1 cursor-pointer" onClick={nextPage}>
                      <img src={rightArrowIcon} alt={rightArrowIcon} />
                    </button>
                  </div>
                )}
              </div>
            )}
            <TransactionModal
              openModal={isModalOpen}
              close={handleCancel}
              transaction={selectedTransaction}
            />
          </div>
        </div>
      </SideBar>
    </Protected>
  );
};

export default Transaction;
