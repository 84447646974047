import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';

const Protected = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  if (user && (!user.is_bvn_verified || !user.is_kyb_verified)) {
    navigate('/');
    return null;
  }

  return <>{children}</>;
};

export default Protected;
