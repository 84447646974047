import { Drawer, notification } from 'antd';
import displayIcon from 'assets/Group 1171275983.svg';
import rightIcon from 'assets/right _arrow.svg';
import { FC, useEffect, useState } from 'react';
import { useGetUserMutation } from 'services/auth';
import { useGeneratetNairaWithdrawMutation, useGetBvnMutation } from 'services/wallet';
import { useAppSelector } from 'store';

interface BankRequestProps {
  isOpen: boolean;
  handleCancel: () => void;
}

const BankRequestModal: FC<BankRequestProps> = ({ isOpen, handleCancel }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [formData, setFormData] = useState({ bvn: '', phoneNumber: '' });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [generateNairaWithdraw] = useGeneratetNairaWithdrawMutation();
  const [getBvn, { data: bvnData }] = useGetBvnMutation();
  const [getUser] = useGetUserMutation();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);

      getBvn({});
      getUser({}).then((response) => {
        if (response.data) {
          const { phone_number } = response.data.data;
          setFormData((prev) => ({
            ...prev,
            phoneNumber: phone_number ? `+234${phone_number.replace(/^\+234|^0/, '')}` : '',
          }));
        }
      });

      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
      setIsRequesting(false);
    }
  }, [isOpen, getBvn, getUser]);

  const handleGenerateAccount = async () => {
    try {
      const payload = {
        bvn: formData.bvn,
        phone_number: formData.phoneNumber,
      };

      await generateNairaWithdraw(payload).unwrap();

      notification.success({
        message: 'Request Successful',
        description: 'Account generation has started!',
      });

      setFormData({ bvn: '', phoneNumber: '' });
      handleCancel();
    } catch (error) {
      notification.error({
        message: 'Request Failed',
        description: 'An error occurred while generating the account. Please try again.',
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      const formattedValue = value.replace(/^\+234|^0/, '');
      setFormData((prev) => ({
        ...prev,
        [name]: `+234${formattedValue}`,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    if (value) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  const handleRequestClick = () => setIsRequesting(true);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const newErrors: { [key: string]: string } = {};

    if (!bvnData?.data?.has_bvn && formData.bvn === '') {
      newErrors.bvn = 'BVN is required and must be 11 digits';
    } else if (formData.bvn && !/^\d{11}$/.test(formData.bvn)) {
      newErrors.bvn = 'BVN must be exactly 11 digits';
    }

    if (!user?.phone_number && formData.phoneNumber === '') {
      newErrors.phoneNumber = 'Phone number is required and must be 10 digits';
    } else if (formData.phoneNumber && !/^\d{10}$/.test(formData.phoneNumber.replace('+234', ''))) {
      newErrors.phoneNumber = 'Phone number must be 10 digits';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const payload = {
      bvn: formData.bvn,
      phone_number: formData.phoneNumber,
    };

    try {
      await generateNairaWithdraw(payload).unwrap();

      notification.success({
        message: 'Request Successful',
        description: 'Account generation has started!',
      });

      setFormData({ bvn: '', phoneNumber: '' });
      handleCancel();
    } catch (error) {
      notification.error({
        message: 'Request Failed',
        description: 'An error occurred while submitting your request. Please try again.',
      });
    }
  };

  const showPhoneAndBvnInputs =
    !bvnData?.data?.has_bvn || !formData.phoneNumber || !user?.phone_number;
  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>{isRequesting ? 'Request Account' : 'Add Account'}</p>}
      open={isOpen}
      loading={loading}
      onClose={handleCancel}
      width={600}
    >
      {isRequesting ? (
        <div className="flex flex-col gap-6">
          <p className="leading-[21.6px] font-normal text-[16px] text-[#000000]">
            {showPhoneAndBvnInputs
              ? 'Kindly provide the following information to get a bank account'
              : 'Please click the button to generate your account.'}
          </p>
          {showPhoneAndBvnInputs ? (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-[16px] text-[#000000] font-medium text-[16px]"
            >
              {!bvnData?.data?.has_bvn && (
                <div className="flex flex-col gap-1">
                  <p>BVN (Bank Verification Number)*</p>
                  <input
                    type="text"
                    name="bvn"
                    placeholder="Enter BVN"
                    value={formData.bvn}
                    onChange={handleInputChange}
                    className="h-[54px] rounded-[12px] px-[20px] border border-[#D0D0D0]"
                  />
                  {errors.bvn && <span className="text-red-500 text-sm">{errors.bvn}</span>}
                </div>
              )}
              {!user?.phone_number && (
                <div className="flex flex-col gap-1">
                  <p>Phone Number*</p>
                  <div className="flex items-center border border-[#D0D0D0] rounded-[12px] h-[54px]">
                    <span className="px-3 text-[#000000] font-medium">+234</span>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Enter phone number"
                      value={formData.phoneNumber.replace(/^\+234/, '')}
                      onChange={handleInputChange}
                      className="flex-1 px-[20px] border-l border-[#D0D0D0] focus:outline-none"
                    />
                  </div>
                  {errors.phoneNumber && (
                    <span className="text-red-500 text-sm">{errors.phoneNumber}</span>
                  )}
                </div>
              )}
              <button
                type="submit"
                className="flex justify-center items-center gap-2 text-[#F9F9FB] px-[20px] h-[40px] bg-[#1174ED] rounded-lg mt-4"
              >
                Send Request
                <img src={rightIcon} alt="right icon" />
              </button>
            </form>
          ) : (
            <button
              onClick={handleGenerateAccount}
              className="flex justify-center items-center gap-2 text-[#F9F9FB] px-[20px] h-[40px] bg-[#1174ED] rounded-lg mt-4"
            >
              Send Request
              <img src={rightIcon} alt="right icon" />
            </button>
          )}
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="flex flex-col items-center gap-4 pt-12">
            <img src={displayIcon} alt="No bank account" />
            <p className="text-[#D6DCE8] leading-[40px] text-[20px] font-[400]">
              You don’t have any bank account yet
            </p>
            <button
              className="flex items-center gap-2 text-[#F9F9FB] px-[20px] h-[40px] bg-[#1174ED] rounded-lg font-medium mt-4 leading-[29.61px] text-[18px]"
              onClick={handleRequestClick}
            >
              Request for a bank account
            </button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default BankRequestModal;
