/* eslint-disable @typescript-eslint/no-explicit-any */
import Activate from 'components/userComponent/bank/ActivateBankDetails';
import BankDetails from 'components/userComponent/bank/BankDetails';
import Info from 'components/userComponent/bank/Info';
import Processing from 'components/userComponent/bank/Processing';
import { useEffect, useState } from 'react';
import { useGetNairaWalletMutation } from 'services/wallet';

const AccountCheck = () => {
  const [getNaira] = useGetNairaWalletMutation();
  const [walletStatus, setWalletStatus] = useState<string | null>(null);

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response: any = await getNaira({});
        const statusCode = response?.status;
        const status = response?.data?.data?.status;

        if (statusCode === 404 || status === 'Failed') {
          setWalletStatus('Failed');
        } else {
          setWalletStatus(status || null);
        }
      } catch (error) {
        setWalletStatus('Failed');
      }
    };

    fetchWalletData();
    const intervalId = setInterval(fetchWalletData, 8000);

    return () => clearInterval(intervalId);
  }, [getNaira]);

  const renderSection = (LeftComponent: React.ElementType) => (
    <div className="flex flex-col lg:flex-row justify-between gap-[20px] w-full">
      <div className="lg:w-[50%] w-full">
        <LeftComponent />
      </div>
      <div className="lg:w-[50%] w-full">
        <Info />
      </div>
    </div>
  );

  return (
    <div>
      {(walletStatus === 'Failed' || walletStatus === null) && renderSection(Activate)}
      {walletStatus === 'Processing' && renderSection(Processing)}
      {walletStatus === 'Completed' && renderSection(BankDetails)}
    </div>
  );
};

export default AccountCheck;
