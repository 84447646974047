/* eslint-disable @typescript-eslint/no-explicit-any */
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { notification } from 'antd';
import Logo from 'assets/astrabiz-logo.png';
import axios from 'axios';
import ErrorCard from 'components/ErrorCard';
import Loader from 'components/Loader';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/path';
import { useRegisterMutation } from 'services/auth';
import { useAppDispatch } from 'store';
import { setEmail, setPassword } from 'store/auth';
import { RegisterInput } from 'types/auth';
import AuthLayout from 'views/auth/AuthLayout';
import VerifyEmail from 'views/auth/VerifyEmail';
import * as Yup from 'yup';

const Register = () => {
  const [error, setError] = useState<any>();
  const [page, setPage] = useState(0);

  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState<any>(null);
  const [countries, setCountries] = useState([
    { name: 'Nigeria', code: 'NG', flag: 'https://flagcdn.com/w320/ng.png', dial_code: '+234' },
  ]);

  useEffect(() => {
    const countriesApiUrl = import.meta.env.VITE_COUNTRIES_API_URL;
    const ipApiUrl = import.meta.env.VITE_IP_API_URL;

    fetch(countriesApiUrl)
      .then((response) => response.json())
      .then((data) => {
        const countriesList = data.map((country: any) => ({
          name: country.name.common,
          code: country.cca2,
          flag: country.flags.png,
          dial_code: country.idd?.root + (country.idd?.suffixes?.[0] || ''),
        }));
        setCountries(countriesList);
        axios
          .get(ipApiUrl)
          .then((response) => {
            const countryCode = response.data.country;
            const userCountry = countriesList.find((country: any) => country.code === countryCode);
            setSelected(userCountry);
          })
          .catch((error) => {
            notification.error({
              message: 'Error',
              description: error,
            });
          });
      });
  }, []);

  const initialValues: RegisterInput = {
    address: '',
    business_email_address: '',
    country_of_business_registration: '',
    last_name: '',
    first_name: '',
    phone_number: '',
    password: '',
    registered_business_name: '',
  };

  const [register, { isLoading: isLoadingRegister }] = useRegisterMutation();

  const validationSchema: Yup.Schema<RegisterInput> = Yup.object().shape({
    address: Yup.string().required(),
    business_email_address: Yup.string().email().required('business email is a required field'),
    last_name: Yup.string().required('last name is a required field'),
    first_name: Yup.string().required('first name is a required field'),
    phone_number: Yup.string()
      .required('Phone number is a required field')
      .matches(
        /^\d{10}$/,
        'Enter the remaining 10 digits of your phone number (country code is already included)'
      ),
    password: Yup.string()
      .min(8)
      .required()
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
        'Password must contain at least one uppercase, one lowercase, one number and one special character'
      ),
    registered_business_name: Yup.string().required('registered business name is a required field'),
  });

  const handleSubmit = (data: RegisterInput) => {
    const phoneNumberWithCode = `${selected?.dial_code || '+234'}${data.phone_number}`;

    const payload = {
      ...data,
      country_of_business_registration: selected?.code,
      phone_number: phoneNumberWithCode,
    };

    register(payload)
      .unwrap()
      .then((response) => {
        dispatch(setEmail(data.business_email_address));
        dispatch(setPassword(data.password));
        response && setPage(1);
      })
      .catch((error: any) => {
        setError(error.data.message);
      });
  };

  useEffect(() => {
    document.title = 'AstraBiz | Register';
  }, []);

  return (
    <AuthLayout>
      {!page ? (
        <div className="p-6 mx-auto w-[80%]">
          <img src={Logo} alt="astravest-logo" />
          <p className="mt-10 text-2xl font-black">Create your secure account</p>
          {error ? <ErrorCard text={error} /> : null}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: RegisterInput) => handleSubmit(values)}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              handleBlur,
            }: FormikProps<RegisterInput>) => (
              <Form onSubmit={handleSubmit}>
                <div className="mt-5 grid">
                  <label htmlFor="registered_business_name" className="text-base font-medium">
                    Registered Business Name*
                  </label>
                  <Field
                    name="registered_business_name"
                    className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                    placeholder="Registered Business Name"
                    onBlur={handleBlur('registered_business_name')}
                    onChange={handleChange('registered_business_name')}
                    value={values.registered_business_name}
                  />
                  {errors.registered_business_name && touched.registered_business_name ? (
                    <p className="text-sm font-bold text-red-500">
                      {errors.registered_business_name}
                    </p>
                  ) : null}
                </div>
                <div className="mt-3 grid">
                  <label htmlFor="first_name" className="text-base font-medium">
                    First Name*
                  </label>
                  <Field
                    name="first_name"
                    className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                    placeholder="First Name"
                    onBlur={handleBlur('first_name')}
                    onChange={handleChange('first_name')}
                    value={values.first_name}
                  />
                  {errors.first_name && touched.first_name ? (
                    <p className="text-sm font-bold text-red-500">{errors.first_name}</p>
                  ) : null}
                </div>

                <div className="mt-3 grid">
                  <label htmlFor="last_name" className="text-base font-medium">
                    Last Name*
                  </label>
                  <Field
                    name="last_name"
                    className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                    placeholder="Last Name"
                    onBlur={handleBlur('last_name')}
                    onChange={handleChange('last_name')}
                    value={values.last_name}
                  />
                  {errors.last_name && touched.last_name ? (
                    <p className="text-sm font-bold text-red-500">{errors.last_name}</p>
                  ) : null}
                </div>
                <div className="mt-3 grid">
                  <label
                    htmlFor="country_of_business_registration"
                    className="text-base font-medium"
                  >
                    Country of Busineess Registration*
                  </label>
                  <Listbox value={selected} onChange={setSelected}>
                    <div className="relative mt-2">
                      <Listbox.Button className="relative border w-full cursor-default rounded-xl text-sm p-3">
                        <div className="flex space-x-2">
                          {selected ? (
                            <>
                              <img
                                src={selected.flag}
                                alt={selected.code}
                                className="w-5 h-5 my-auto"
                              />
                              <span className="block truncate my-auto">{selected.name}</span>
                            </>
                          ) : (
                            <span className="block truncate my-auto">Select a country</span>
                          )}
                        </div>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {countries.map((country, countryIdx) => (
                            <Listbox.Option
                              key={countryIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                }`
                              }
                              value={country}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {country.name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>

                <div className="mt-3 grid">
                  <label htmlFor="business_email_address" className="text-base font-medium">
                    Business Email*
                  </label>
                  <Field
                    name="business_email_address"
                    className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                    placeholder="name@company.com"
                    type="email"
                    onBlur={handleBlur('business_email_address')}
                    onChange={handleChange('business_email_address')}
                    value={values.business_email_address}
                  />
                  {errors.business_email_address && touched.business_email_address ? (
                    <p className="text-sm font-bold text-red-500">
                      {errors.business_email_address}
                    </p>
                  ) : null}
                </div>

                <div className="mt-3 grid">
                  <label htmlFor="address" className="text-base font-medium">
                    Address*
                  </label>
                  <Field
                    name="address"
                    className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                    placeholder="enter your address"
                    onBlur={handleBlur('address')}
                    onChange={handleChange('address')}
                    value={values.address}
                  />
                  {errors.address && touched.address ? (
                    <p className="text-sm font-bold text-red-500">{errors.address}</p>
                  ) : null}
                </div>

                <div className="mt-3 grid">
                  <label htmlFor="phone_number" className="text-base font-medium">
                    Phone Number*
                  </label>
                  <div className="flex items-center border rounded-xl mt-2 p-1">
                    <span className="ml-2 text-sm font-medium">
                      {selected?.dial_code || '+234'}
                    </span>
                    <Field
                      name="phone_number"
                      className="flex-1 ml-2 p-2 placeholder:text-sm"
                      placeholder="Enter phone number"
                      onBlur={handleBlur('phone_number')}
                      onChange={handleChange('phone_number')}
                      value={values.phone_number}
                    />
                  </div>
                  {errors.phone_number && touched.phone_number ? (
                    <p className="text-sm font-bold text-red-500">{errors.phone_number}</p>
                  ) : null}
                </div>

                <div className="mt-3 grid">
                  <label htmlFor="password" className="text-base font-medium">
                    Password*
                  </label>
                  <input
                    name="password"
                    className="border mt-2 p-3 rounded-xl placeholder:text-sm"
                    placeholder="************"
                    type="password"
                    onBlur={handleBlur('password')}
                    onChange={handleChange('password')}
                    value={values.password}
                  />
                  {errors.password && touched.password ? (
                    <p className="text-sm font-bold text-red-500">{errors.password}</p>
                  ) : null}
                </div>

                <p className="mt-10 text-base font-medium">
                  By creating your account, you agree to our{' '}
                  <span className="font-bold">Terms and Services.</span>
                </p>
                {isLoadingRegister ? (
                  <Loader className="mt-2" />
                ) : (
                  <button
                    className="mt-2 w-full text-center bg-astravest-blue text-white py-3 rounded-xl text-xl font-bold"
                    type="submit"
                  >
                    Get Started
                  </button>
                )}
                <p className="mt-5 text-base font-medium">
                  Already have an account?{' '}
                  <Link to={LOGIN_ROUTE} className="cursor-pointer font-bold">
                    Log In
                  </Link>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <VerifyEmail from="register" />
      )}
    </AuthLayout>
  );
};

export default Register;
