import ngnIcon from 'assets/nga.svg';
import addIcon from 'assets/plus.svg';
import BankRequestModal from 'components/userComponent/bank/BankRequestModal';
import { useState } from 'react';

const Activate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col gap-5 lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px] w-full">
      <h1 className="font-[900] text-[18px] lg:text-[24px] text-[#343434] leading-[40px]">
        Bank Details
      </h1>
      <div className="flex lg:flex-row flex-col justify-between items-center gap-[20px] lg:gap-[30px] md:gap-0 lg:mt-6 w-full">
        <div className="flex items-center gap-2 lg:w-[60%]">
          <div>
            <img src={ngnIcon} alt="ngn" width={42} height={42} />
          </div>
          <div className="flex flex-col gap-1 w-[219px]">
            <h1 className="text-[#343434] font-bold leading-[18px]">Bank Account Request</h1>
            <p className="text-[12px] leading-[16.2px] font-medium text-[#666666]">
              Please click the 'Get Account' button to activate your NGN account.
            </p>
          </div>
        </div>
        <button
          onClick={showModal}
          className="flex items-center gap-2 text-[#fff] px-[20px] text-nowrap h-[40px] bg-[#1174ED] rounded-lg"
        >
          <img src={addIcon} alt="btn" />
          Get Account
        </button>
        <BankRequestModal isOpen={isModalOpen} handleCancel={handleCancel} />
      </div>
    </div>
  );
};

export default Activate;
