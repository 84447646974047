import { Drawer } from 'antd';
import BenefitIcon from 'assets/Benefit';
import logoIcon from 'assets/brand_logo.svg';
import HomeIcon from 'assets/HomeIcon';
import TransIcon from 'assets/Transaction';
import AccountIcon from 'assets/Wallet';
import { FC, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGetUserMutation } from 'services/auth';
import { useAppDispatch } from 'store';
import { setUser } from 'store/auth';
import { User } from 'types/auth';

import 'styles/dashboard.css';

interface NavItemProps {
  to: string | string[];
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
}

const NavItem: FC<NavItemProps> = ({ to, icon, label, disabled = false }) => {
  const { pathname } = useLocation();
  const isActive = Array.isArray(to) ? to.includes(pathname) : pathname === to;

  return (
    <>
      {disabled ? (
        <div className="das1 text-[#c2c0c0] font-[400] text-[16px] leading-[22.4px] flex items-center cursor-not-allowed gap-[10px] h-[36px]">
          {icon}
          <p>{label}</p>
        </div>
      ) : isActive ? (
        <div className="das text-[#1174ED] font-[700] flex justify-between items-center w-[100%]">
          <div className="flex items-center gap-[10px]">
            {icon}
            <p>{label}</p>
          </div>
        </div>
      ) : (
        <NavLink to={Array.isArray(to) ? to[0] : to}>
          <div className="flex items-center gap-[10px] cursor-pointer text-[#09090A] font-[400] text-[16px] leading-[22.4px] h-[36px]">
            {icon}
            <p>{label}</p>
          </div>
        </NavLink>
      )}
    </>
  );
};

interface MobileSideBarProps {
  isKycVerified: boolean;
  isSideBarOpen: boolean;
  sideBarHandleCancel: () => void;
}

const MobileSideBar: FC<MobileSideBarProps> = ({
  isKycVerified,
  isSideBarOpen,
  sideBarHandleCancel,
}) => {
  const [getUser] = useGetUserMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUser({})
      .unwrap()
      .then((response: { data: User }) => {
        dispatch(setUser(response.data));
      });
  }, [dispatch, getUser]);

  const sidebarItems = useMemo(
    () => [
      { to: `/`, icon: <HomeIcon />, label: 'Dashboard' },
      ...(isKycVerified
        ? [
            { to: `/beneficiaries`, icon: <BenefitIcon />, label: 'Beneficiaries' },
            { to: `/accounts`, icon: <AccountIcon />, label: 'Accounts' },
            { to: `/transactions`, icon: <TransIcon />, label: 'Transactions' },
          ]
        : [
            { to: `/beneficiaries`, icon: <BenefitIcon />, label: 'Beneficiaries', disabled: true },
            { to: `/accounts`, icon: <AccountIcon />, label: 'Accounts', disabled: true },
            { to: `/transactions`, icon: <TransIcon />, label: 'Transactions', disabled: true },
          ]),
    ],
    [isKycVerified]
  );

  useEffect(() => {
    const selectedTab = sidebarItems.find((item) =>
      Array.isArray(item.to)
        ? item.to.includes(window.location.pathname)
        : item.to === window.location.pathname
    );

    if (selectedTab) {
      document.title = `AstraBiz | ${selectedTab.label}`;
    }
  }, [sidebarItems]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isSideBarOpen) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
    }
  }, [isSideBarOpen]);

  return (
    <Drawer
      closable
      destroyOnClose
      title={<img src={logoIcon} alt={logoIcon} className="h-[63px]" />}
      open={isSideBarOpen}
      loading={loading}
      onClose={sideBarHandleCancel}
      width={250}
      placement="left"
    >
      <div className="flex flex-col gap-4 items-cente text-[#09090A] text-[16px] not-italic font-[400] leading-[22.4px] w-[100%] pl-[10px]">
        {sidebarItems.map((item, index) => (
          <NavItem
            key={index}
            to={item.to}
            icon={item.icon}
            label={item.label}
            disabled={!isKycVerified && index !== 0}
          />
        ))}
      </div>
    </Drawer>
  );
};

export default MobileSideBar;
