/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checkbox,
  Drawer,
  notification,
  Select,
  Spin,
  Table,
  TableColumnsType,
  Upload,
} from 'antd';
import arrowDownIcon from 'assets/Arrow - Down 2.svg';
import leftArrowIcon from 'assets/Arrow - Left Square.svg';
import rightArrowIcon from 'assets/Arrow - Right Square.svg';
import uploadIcon from 'assets/file upload states.svg';
import ngnIcon from 'assets/flag-for-nigeria-svgrepo-com 1.svg';
import usaIcon from 'assets/Group 1171275979.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import indicatorOne from 'assets/indicator1.svg';
import twoIcon from 'assets/indicatortwo.svg';
import numberTwoIcon from 'assets/line 3 - icon + text + line.svg';
import cynIcon from 'assets/Mask group.svg';
import searchIcon from 'assets/search.svg';
import tickIcon from 'assets/Tick Square.svg';
import plusIcon from 'assets/Vector (1).svg';
import useTableScroll from 'components/useTableScroll';
import { FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useGetBeneficiaryMutation } from 'services/beneficiaries';
import {
  useGetAllWalletMutation,
  useGetPurposeMutation,
  useWalletPayOutMutation,
} from 'services/wallet';
import { useAppDispatch } from 'store';
import { setBeneficiary } from 'store/beneficiaries';
import { setPayOut, setPurpose } from 'store/wallet';

import AddBeneficiaries from './AddBeneficiaryModal';

interface SendMoneyProps {
  openMoneyModal: boolean;
  closeModaL: () => void;
}

interface DataType {
  key: React.Key;
  account_name: string;
  currency: string;
  country?: string;
  account_number: string;
  status?: string;
  bank_name: string;
}

interface ErrorResponse {
  data: {
    message: string;
  };
}

interface CountryIcon {
  src: string;
  alt: string;
}

interface AccountBalances {
  [currency: string]: string;
}

interface Wallet {
  id: string;
  currency: string;
  available: string;
}

interface WalletResponse {
  data: Wallet[];
}

const SendMoney: FC<SendMoneyProps> = ({ openMoneyModal, closeModaL }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getBeneficiary] = useGetBeneficiaryMutation();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<DataType[]>([]);
  const [filteredData, setFilteredData] = useState<DataType[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [sendAmount, setSendAmount] = useState<string>('');
  const [showBeneficiary, setShowBeneficiary] = useState<boolean>(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<string | null>(null);
  const [selectedBeneficiaryName, setSelectedBeneficiaryName] = useState<string | null>(null);
  const [getWalletPayOut] = useWalletPayOutMutation();
  const [getWalletById] = useGetAllWalletMutation();
  const [accountBalances, setAccountBalances] = useState<AccountBalances>({});
  const [getPurpose] = useGetPurposeMutation();
  const [purposes, setPurposes] = useState<{ id: string; title: string }[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [selectIcon, setSelectIcon] = useState<CountryIcon>({ src: ngnIcon, alt: 'NGN Icon' });
  const dropdownRefs = useRef<HTMLDivElement>(null);
  const [dropOpen, setDropOpen] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('NGN');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const scrollX = useTableScroll();

  const { Option } = Select;

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Approved':
        return { backgroundColor: '#ECFDF3', color: '#037847', dotColor: '#037847' };
      default:
        return {};
    }
  };

  const formatAccountNumber = (accountNumber: string) => {
    if (!accountNumber) return '';
    if (accountNumber.length <= 6) return accountNumber;
    const firstThree = accountNumber.slice(0, 3);
    const lastThree = accountNumber.slice(-3);
    return `${firstThree}****${lastThree}`;
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'checkbox',
      render: (_, record) => (
        <Checkbox
          checked={selectedBeneficiary === record.key}
          onChange={() => handleCheckboxChange(record)}
        />
      ),
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      render: (text) => formatAccountNumber(text),
    },
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        const { backgroundColor, color, dotColor } = getStatusStyle(text);
        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: '2px 8px 2px 6px',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '22px',
            }}
          >
            <div
              style={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: dotColor,
                marginRight: '8px',
              }}
            ></div>
            {text}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (sendAmount !== '') {
      setShowBeneficiary(true);
    } else {
      setShowBeneficiary(false);
    }
  }, [sendAmount]);

  const handleSendAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setSendAmount(value);
    }
  };

  const handleSubmit = () => {
    if (selectedBeneficiary && selectedPurpose && sendAmount && selectedCurrency) {
      const amountNumber = parseFloat(sendAmount);
      if (isNaN(amountNumber)) {
        notification.error({
          message: 'Error',
          description: 'Please enter a valid number for the amount.',
        });
        return;
      }
      getWalletPayOut({
        beneficiary: selectedBeneficiary,
        purpose: selectedPurpose,
        currency: selectedCurrency,
        amount: amountNumber,
      })
        .unwrap()
        .then((response) => {
          dispatch(setPayOut(response.data));
          notification.success({
            message: 'Success',
            description: 'Payout initiated.',
          });
          closeModaL();
          setSendAmount('');
          setSelectedBeneficiary(null);
          setSelectedPurpose(null);
          setSelectedCurrency('NGN');
          setShowConfirmation(false);
        })
        .catch((error) => {
          const err = error as ErrorResponse;
          if (
            err &&
            typeof err === 'object' &&
            'data' in err &&
            typeof err.data === 'object' &&
            'message' in err.data
          ) {
            notification.error({
              message: 'Error',
              description: err.data.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    getPurpose({})
      .unwrap()
      .then((response) => {
        setPurposes(response.data);
        dispatch(setPurpose(response.data));
      });
  }, [dispatch, getPurpose]);

  const fetchBeneficiaries = useCallback(() => {
    setLoading(true);
    getBeneficiary({})
      .unwrap()
      .then((response) => {
        const formattedData = response.data.map(
          (item: {
            id: string;
            account_name: string;
            currency: string;
            account_number: string;
            bank_name: string;
          }) => ({
            key: item.id,
            account_name: item.account_name,
            currency: item.currency,
            account_number: formatAccountNumber(item.account_number),
            status: 'Approved',
            bank_name: item.bank_name,
          })
        );
        setData(formattedData);
        setFilteredData(formattedData);
        dispatch(setBeneficiary(response.data));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, getBeneficiary]);

  useEffect(() => {
    fetchBeneficiaries();
  }, [fetchBeneficiaries]);

  useEffect(() => {
    setFilteredData(
      data.filter((item) => item.account_name.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [searchQuery, data]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (record: DataType) => {
    setSelectedBeneficiary(record.key as string);
    setSelectedBeneficiaryName(record.account_name);
    setShowConfirmation(selectedPurpose !== null && record.key !== null);
  };

  const handleSelectChange = (value: SetStateAction<null>) => {
    setSelectedPurpose(value);
    setShowConfirmation(value !== null && selectedBeneficiary !== null);
  };

  useEffect(() => {
    const fetchWalletBalances = async () => {
      try {
        const walletResponse: WalletResponse = await getWalletById({}).unwrap();
        const balances: AccountBalances = {};

        walletResponse.data.forEach((wallet) => {
          balances[wallet.currency] = parseFloat(wallet.available).toFixed(2);
        });

        setAccountBalances(balances);
      } catch (error) {
        // console.error('Failed to fetch wallet balances:', error);
      }
    };

    fetchWalletBalances();
  }, [getWalletById]);

  const availableCurrencies = Object.keys(accountBalances);

  const handleSelect = (icon: CountryIcon, currency: string) => {
    setSelectIcon(icon);
    setSelectedCurrency(currency);
    setDropOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRefs.current && !dropdownRefs.current.contains(event.target as Node)) {
        setDropOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRefs]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / pageSize)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (openMoneyModal) {
      const timer = setTimeout(() => {
        setLoad(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoad(true);
    }
  }, [openMoneyModal]);

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>Send Money</p>}
      placement="right"
      loading={load}
      open={openMoneyModal}
      onClose={() => closeModaL()}
      width={700}
    >
      <div className="flex flex-col gap-3">
        <div className="flex gap-2">
          <div className="flex flex-col items-center gap-2 relative top-2">
            <div className="h-[24px] w-[24px]">
              <img src={indicatorOne} alt="indicatorOne" />
            </div>
            <div className="border-[1.9px] border-[rgb(17,116,237)] h-[100%]"></div>
          </div>
          <div className="flex flex-col w-[100%]">
            <h1 className="font-[900] text-[16px] lg:text-[20px] leading-[40px] text-[#343434]">
              Choose Amount
            </h1>
            <div className="flex flex-col gap-4 border-[0.5px] border-[#EAECF0] lg:w-[550px] rounded-[10px] p-[20px]">
              <div ref={dropdownRefs} className="flex flex-col gap-1 lg:w-[360px]">
                <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">
                  I want to send*
                </p>
                <div className="input">
                  <div className="flex gap-8 relative left-3 top-4 z-20 w-[30%] lg:w-[20%]">
                    <img
                      src={selectIcon.src}
                      alt={selectIcon.alt}
                      className="w-[25.2px] h-[25.2px]"
                    />
                    <button onClick={() => setDropOpen(!dropOpen)}>
                      <img
                        src={arrowDownIcon}
                        alt={arrowDownIcon}
                        className="w-[11.67px] h-[5.83px]"
                      />
                    </button>
                  </div>
                  <input
                    value={sendAmount}
                    onChange={handleSendAmountChange}
                    placeholder="Enter amount"
                    className="h-[46px] pl-[120px] pr-3 w-full z-10 border border-[#D0D0D0] rounded-[12px] relative bottom-[20px] -mb-[20px] right-2 -mr-2"
                  />
                </div>
                {dropOpen && (
                  <div className="bg-shadow flex flex-col bg-white absolute mt-[5rem] w-[250px] lg:w-[350px] font-[500]">
                    {availableCurrencies.includes('USD') && (
                      <button
                        onClick={() => handleSelect({ src: usaIcon, alt: 'USD Icon' }, 'USD')}
                        className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                      >
                        <img src={usaIcon} alt={usaIcon} className="w-[25.2px] h-[25.2px]" /> USD
                      </button>
                    )}
                    {availableCurrencies.includes('NGN') && (
                      <button
                        onClick={() => handleSelect({ src: ngnIcon, alt: 'NGN Icon' }, 'NGN')}
                        className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                      >
                        <img src={ngnIcon} alt={ngnIcon} className="w-[25.2px] h-[25.2px]" /> NGN
                      </button>
                    )}
                    {availableCurrencies.includes('CNY') && (
                      <button
                        onClick={() => handleSelect({ src: cynIcon, alt: 'CNY Icon' }, 'CNY')}
                        className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                      >
                        <img src={cynIcon} alt={cynIcon} className="w-[25.2px] h-[25.2px]" /> CNY
                      </button>
                    )}
                    {availableCurrencies.includes('HKD') && (
                      <button
                        onClick={() => handleSelect({ src: hkdIcon, alt: 'HKD Icon' }, 'HKD')}
                        className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                      >
                        <img src={hkdIcon} alt={hkdIcon} className="w-[25.2px] h-[25.2px]" /> HKD
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:flex-row gap-3 items-center">
                <div className="flex justify-center items-center bg-[#F5F5F5] py-[15px] rounded-[10px] w-full lg:w-[35%]">
                  <div className="flex flex-col items-center">
                    <p className="text-[#666666] font-[500] text-[12px] leading-[16.2px]">
                      Available Balance:
                    </p>
                    <h1 className="text-[#343434] font-[900] text-center text-[16px] leading-[20px]">
                      {accountBalances[selectedCurrency]
                        ? parseFloat(accountBalances[selectedCurrency]).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 'not found'}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-center items-center bg-[#F5F5F5] py-[15px] rounded-[10px] w-full lg:w-[35%]">
                  <div className="flex flex-col items-center">
                    <p className="text-[#666666] font-[500] text-[12px] leading-[16.2px]">
                      Total Balance:
                    </p>
                    <h1 className="text-[#343434] font-[900] text-center text-[16px] leading-[20px]">
                      {accountBalances[selectedCurrency]
                        ? parseFloat(accountBalances[selectedCurrency]).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 'not found'}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showBeneficiary && (
          <div className="flex gap-2">
            <div className="flex flex-col items-center gap-2 relative top-2">
              <div className="h-[24px] w-[24px]">
                <img src={twoIcon} alt="w-[24px] h-[24px]" />
              </div>
              <div className="border-[1.9px] border-[rgb(17,116,237)] h-[100%]"></div>
            </div>
            <div className="flex flex-col w-[100%]">
              <h1 className="font-[900] text-[16px] lg:text-[20px] leading-[40px] text-[#343434]">
                Select Beneficiary
              </h1>
              <div className="border-[0.5px] border-[#EAECF0] py-[8px] rounded-[10px] p-[15px]">
                <div className="flex flex-col gap-4 lg:gap-8 pt-[30px]">
                  <div className="flex justify-between">
                    <div className="input lg:flex hidden items-center">
                      <img
                        src={searchIcon}
                        alt={searchIcon}
                        className="relative left-[30px] -ml-[18px]"
                      />
                      <input
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        type="text"
                        placeholder="Search for Beneficiary"
                        className="bg-[#F5F5F5] pl-[35px] pr-[10px] rounded-[10px] h-[40px] py-[16.21px] w-[222px]"
                      />
                    </div>
                    <div className="flex items-center gap-6">
                      <div className="lg:flex hidden items-center gap-2 font-[500] text-[14px] leading-[20px]">
                        <img src={refreshIcon} alt={refreshIcon} />
                        <p>Refresh</p>
                      </div>
                      <div className="flex items-center gap-2 border border-[#1174ED] py-[7px] px-[20px] rounded-[10px] h-[40px] text-[#1174ED] font-[700] text-[14px] leading-[18.86px]">
                        <img src={plusIcon} alt={plusIcon} />
                        <button onClick={showModal}>Add Beneficiary</button>
                      </div>
                      <AddBeneficiaries
                        isOpen={isModalOpen}
                        modalClose={handleCancel}
                        fetchBeneficiaries={fetchBeneficiaries}
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 font-[700] text-[12.57px] leading-[18.86px] text-[#8894EC]">
                    <button className="flex justify-center items-center border border-[#8894EC] bg-[#8894EC33] rounded-[30px] h-[33px] py-[7px] px-[20px]">
                      Your Accounts
                    </button>
                    <button className="flex justify-center items-center border border-[#8894EC] rounded-[30px] h-[33px] py-[7px] px-[20px]">
                      Beneficiaries
                    </button>
                  </div>
                </div>

                <div className="pt-[20px] lg:pt-[40px]">
                  {loading ? (
                    <div
                      className="flex justify-center items-center"
                      style={{ minHeight: '150px' }}
                    >
                      <Spin size="large" />
                    </div>
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={filteredData}
                      size="middle"
                      className="send_money_table w-[100%]"
                      pagination={{ current: currentPage }}
                      scroll={{
                        x: scrollX,
                      }}
                    />
                  )}
                </div>
                {filteredData.length > pageSize && (
                  <div className="text-[#1174ED] font-[700] text-[14px] leading-[18.9px] flex justify-end py-[10px] pr-[20px] items-center gap-2">
                    <button className="mx-1 cursor-pointer" onClick={handlePrevPage}>
                      <img src={leftArrowIcon} alt={leftArrowIcon} />
                    </button>
                    <button className="mx-1 cursor-pointer" onClick={handleNextPage}>
                      <img src={rightArrowIcon} alt={rightArrowIcon} />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-6 mt-[40px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[20px]">
                <div className="flex flex-col lg:flex-row justify-between gap-4 w-[100%]">
                  <div className="flex flex-col gap-1 lg:w-[50%]">
                    <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">
                      Purpose of Payment*
                    </p>
                    <Select
                      placeholder="Select purpose"
                      suffixIcon={
                        <img src={arrowDownIcon} alt="arrow-down" className="w-[15px] h-[15px]" />
                      }
                      onChange={handleSelectChange}
                      value={selectedPurpose}
                    >
                      {purposes.map((purpose) => (
                        <Option key={purpose.id} value={purpose.id}>
                          {purpose.title}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div className="flex flex-col gap-1 lg:w-[50%]">
                    <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">
                      Description (optional)
                    </p>
                    <input
                      type="text"
                      placeholder="Transaction description"
                      className="w-[100%] px-[20px] border border-[#D0D0D0] rounded-[8px] h-[54px]"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">
                    Upload supporting Documents (optional)
                  </p>
                  <div className="flex justify-between px-[20px] h-[54px] items-center border border-[#D0D0D0] rounded-[8px] text-[#D0D0D0] font-[500] text-[14px] leading-[19.6px]">
                    <p>Select document(s) to upload</p>
                    <Upload>
                      <button className="flex gap-2 items-center text-[#1174ED] font-[500] text-[14px] leading-[20.3px]">
                        <img src={uploadIcon} alt={uploadIcon} />
                        <p>Click to upload</p>
                      </button>
                    </Upload>
                  </div>
                </div>
                <div className="custom text-[#FFFFFF] font-[700] text-[14px] leading-[18.86px]">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className={`py-[7px] ${
                      selectedBeneficiary && selectedPurpose
                        ? 'bg-[#1174ED] border-[#1174ED] text-white'
                        : 'bg-gray-400 border-gray-400 text-gray-200'
                    } border rounded-[10px] h-[40px] px-[20px]`}
                    disabled={!selectedBeneficiary || !selectedPurpose}
                  >
                    Send funds to bank account
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showConfirmation && (
          <div className="flex gap-2 pt-[40px]">
            <div className="flex flex-col items-center gap-2 relative top-1">
              <div className="relative top-2">
                <img src={numberTwoIcon} alt="Number Two Icon" />
              </div>
            </div>
            <div className="flex flex-col w-[100%]">
              <h1 className="font-[900] text-[20px] leading-[40px] text-[#343434]">
                Confirm Payment
              </h1>
              <div className="flex bg-[#F0FFF9] border border-[#037847] w-[600px py-[8px] rounded-[10px]">
                <div className="flex gap-1 items-center p-[8px]">
                  <img src={tickIcon} alt="Tick Icon" />
                  <div>
                    <h1 className="text-[#27303A] font-[700] text-[16px] leading-[22.4px]">
                      Confirm bank transfer
                    </h1>
                    <p className="font-[500] text-[14px] leading-[18.9px]">
                      You are sending N{sendAmount} to {selectedBeneficiaryName}. Your payment
                      request would be added to the approval queue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default SendMoney;
