import ngnIcon from 'assets/nga.svg';

const Processing = () => {
  return (
    <div className="flex flex-col gap-5 lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px] w-full">
      <h1 className="font-[900] text-[18px] lg:text-[24px] text-[#343434] leading-[40px]">
        Bank Details
      </h1>
      <div className="flex lg:flex-row flex-col justify-between items-center gap-[20px] lg:gap-[30px] md:gap-0 lg:mt-6 w-full">
        <div className="flex items-center gap-2 w-[70%]">
          <div>
            <img src={ngnIcon} alt="ngn" width={42} height={42} />
          </div>
          <div className="flex flex-col gap-1 w-[219px]">
            <h1 className="text-[#343434] font-bold leading-[18px]">Bank Account Request</h1>
            <p className="text-[12px] leading-[16.2px] font-medium text-[#666666]">
              Your request is processing and you will be notified when it has been approved.
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center h-[29.32px] px-[10.63px] bg-[#FBB0401A] rounded-[10px] w-[30%]">
          <div className="flex items-center gap-2 text-[#FBB040] font-medium text-[15.95px] leading-[23.92px]">
            <div className="bg-[#FBB040] w-[7.97px] h-[7.97px] rounded"></div>
            <p>Processing</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Processing;
