import { Button, Drawer, Input, notification } from 'antd';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useAgentWithdrawMutation, useWithdrawMutation } from 'services/wallet';
import * as yup from 'yup';

interface WithdrawModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  isAgent: boolean;
  currency: string;
}

interface ApiError {
  data?: {
    errors?: string[];
  };
}

const validationSchema = yup.object({
  amount: yup
    .number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .positive('Amount must be greater than zero')
    .min(1, 'Amount must be greater than zero'),
});

const WithdrawModal: FC<WithdrawModalProps> = ({ isOpen, handleCancel, isAgent, currency }) => {
  const [withdraw, { isLoading: isUserLoading }] = useWithdrawMutation();
  const [agentWithdraw, { isLoading: isAgentLoading }] = useAgentWithdrawMutation();
  const [loading, setLoading] = useState<boolean>(true);

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          amount: Number(values.amount),
          ...(isAgent && { currency }),
        };
        if (isAgent) {
          await agentWithdraw(payload).unwrap();
          notification.success({
            message: 'Agent Withdrawal Successful',
            description: `Your agent withdrawal request of ${values.amount} is currently being processed`,
          });
        } else {
          await withdraw(payload).unwrap();
          notification.success({
            message: 'Withdrawal Successful',
            description: `Your withdrawal request of ${values.amount} is currently being processed`,
          });
        }
        formik.resetForm();
        handleCancel();
      } catch (error) {
        const typedError = error as ApiError;
        const backendErrors = typedError.data?.errors || [];
        const errorMessage =
          backendErrors.length > 0 ? backendErrors.join(', ') : 'Withdrawal failed';

        notification.error({
          message: 'Withdrawal Failed',
          description: errorMessage,
        });
      }
    },
  });

  const handleModalCancel = () => {
    formik.resetForm();
    formik.setErrors({});
    formik.setTouched({});
    handleCancel();
  };

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
    }
  }, [isOpen]);

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>{isAgent ? 'Agent Withdraw' : 'Withdraw'}</p>}
      placement="right"
      open={isOpen}
      loading={loading}
      onClose={() => handleModalCancel()}
      width={500}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-2 text-[15px] relative -top-2">
          <p className="text-[15px] font-[400]">
            Are you sure you want to withdraw from this action? Once confirmed, this process cannot
            be undone.
          </p>
          <div className="flex flex-col gap-4">
            <div>
              <Input
                name="amount"
                placeholder="Amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                status={formik.touched.amount && formik.errors.amount ? 'error' : ''}
              />
              {formik.touched.amount && formik.errors.amount ? (
                <div className="text-red-500">{formik.errors.amount}</div>
              ) : null}
            </div>

            <div className="flex justify-end">
              <Button
                type="primary"
                htmlType="submit"
                loading={isUserLoading || isAgentLoading}
                className="h-[40px] px-[20px] font-medium text-[15px]"
              >
                {isAgent ? 'Withdraw as Agent' : 'Withdraw'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

export default WithdrawModal;
