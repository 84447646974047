/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer } from 'antd';
import leftIcon from 'assets/Arrow - Left b.svg';
import chatIcon from 'assets/Chat.svg';
import Activate from 'components/userComponent/bank/ActivateBankDetails';
import BankDetails from 'components/userComponent/bank/BankDetails';
import Processing from 'components/userComponent/bank/Processing';
import { FC, useEffect, useState } from 'react';
import { useGetNairaWalletMutation } from 'services/wallet';

import Info from './bank/Info';

interface AddFundProps {
  openModal: boolean;
  close: () => void;
}

const AddFund: FC<AddFundProps> = ({ openModal, close }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [getNaira] = useGetNairaWalletMutation();
  const [walletStatus, setWalletStatus] = useState<string | null>(null);

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response: any = await getNaira({});
        const statusCode = response?.status;
        const status = response?.data?.data?.status;

        if (statusCode === 404 || status === 'Failed') {
          setWalletStatus('Failed');
        } else {
          setWalletStatus(status || null);
        }
      } catch (error) {
        setWalletStatus('Failed');
      }
    };

    fetchWalletData();
    const intervalId = setInterval(fetchWalletData, 8000);

    return () => clearInterval(intervalId);
  }, [getNaira]);

  useEffect(() => {
    if (openModal) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
    }
  }, [openModal]);

  const handleSupportClick = () => {
    close();
    window.open('https://wa.me/+2347019648995?text=Hello%2C%20I%20need%20support', '_blank');
  };

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>Add funds to your NGN Account</p>}
      placement="right"
      open={openModal}
      loading={loading}
      onClose={() => close()}
      width={700}
    >
      <div className="flex flex-col gap-3 w-full lg:w-[553px]">
        <div>
          {(walletStatus === 'Failed' || walletStatus === null) && <Activate />}
          {walletStatus === 'Processing' && <Processing />}
          {walletStatus === 'Completed' && <BankDetails />}
        </div>

        <Info />

        <div className="flex justify-between items-center mt-[30px]">
          <button
            onClick={close}
            className="flex justify-center gap-2 items-center text-[#1174ED] text-[16px] leading-[22.4px] font-[700]"
          >
            <img src={leftIcon} alt={leftIcon} />
            Back
          </button>
          <button
            onClick={handleSupportClick}
            className="flex justify-center gap-2 items-center rounded-[10px] bg-[#1174ED] px-[7px] py-[20px] h-[40px] lg:w-[170px] font-[700] text-[12px] lg:text-[14px] text-[#ffff] leading-[18.86px]"
          >
            <img src={chatIcon} alt={chatIcon} />
            Talk to Support
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default AddFund;
