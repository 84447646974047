/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Spin } from 'antd';
import usaIcon from 'assets/Group 1171275979.svg';
import sdgIcon from 'assets/Group 1171275982.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import refreshIcon from 'assets/ic_round-refresh.svg';
import cynIcon from 'assets/Mask group.svg';
import ngnIcon from 'assets/nga.svg';
import searchIcon from 'assets/search.svg';
import AddFund from 'components/userComponent/AddFundModal';
import AccountCheck from 'components/userComponent/bank/AccountCheck';
import Convertion from 'components/userComponent/ConvertionModal';
import CurrencyButton from 'components/userComponent/CurrencyButton';
import SendMoney from 'components/userComponent/SendMoneyModal';
import SideBar from 'components/userComponent/SideBar';
import AccountTable from 'components/userComponent/table/AccountTable';
import WithdrawModal from 'components/WithDrawModal';
import { useCallback, useEffect, useState } from 'react';
import Protected from 'routes/Protected';
import { WalletData } from 'types/Transaction';

const Account = (_props: any) => {
  const [selectedCurrency, setSelectedCurrency] = useState('NGN');
  const [walletData, setWalletData] = useState<WalletData>({
    available: 0,
    balance: 0,
    currency: '',
  });

  type ModalType = 'addFunds' | 'sendMoney' | 'convertPay' | 'withdraw';

  const [modalState, setModalState] = useState<{ [key in ModalType]?: boolean }>({
    addFunds: false,
    sendMoney: false,
    convertPay: false,
    withdraw: false,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const toggleModal = (type: ModalType, isOpen: boolean) => {
    setModalState((prevState) => ({ ...prevState, [type]: isOpen }));
  };

  const showModal = (type: ModalType) => toggleModal(type, true);
  const handleCancel = (type: ModalType) => toggleModal(type, false);

  const [activeButton, setActiveButton] = useState<
    'addFunds' | 'sendMoney' | 'convertPay' | 'withdraw'
  >('addFunds');

  const handleButtonClick = (buttonName: 'addFunds' | 'sendMoney' | 'convertPay' | 'withdraw') => {
    setActiveButton(buttonName);
  };

  const updateWalletData = useCallback(
    (currency: string) => {
      if (_props.accounts && _props.accounts[currency]) {
        const { available, balance } = _props.accounts[currency];
        setWalletData({ available: Number(available), balance: Number(balance), currency });
      } else {
        setWalletData({ available: '', balance: '', currency: `${currency} not found` });
      }
    },
    [_props.accounts]
  );

  const handleCurrencyChange = (currency: string) => {
    setSelectedCurrency(currency);
    updateWalletData(currency);
  };

  const refreshWalletData = () => {
    setIsRotated(!isRotated);
    setLoadingWallet(true);
    setTimeout(() => {
      updateWalletData(selectedCurrency);
      setLoadingWallet(false);
    }, 1000);
  };

  useEffect(() => {
    updateWalletData(selectedCurrency);
  }, [_props.accounts, selectedCurrency, updateWalletData]);

  const currencies = [
    { name: 'NGN', icon: ngnIcon },
    { name: 'USD', icon: usaIcon },
    { name: 'CNY', icon: cynIcon },
    { name: 'SDG', icon: sdgIcon },
    { name: 'HKD', icon: hkdIcon },
  ];

  const getCurrencyIcon = (currency: string) => {
    switch (currency) {
      case 'NGN':
        return ngnIcon;
      case 'USD':
        return usaIcon;
      case 'CNY':
        return cynIcon;
      case 'SDG':
        return sdgIcon;
      case 'HKD':
        return hkdIcon;
      default:
        return '';
    }
  };

  const availableCurrencies = currencies.filter((currency) => {
    const isAvailable = _props.accounts && _props.accounts[currency.name];
    const matchesSearch = currency.name.toLowerCase().includes(searchTerm.toLowerCase());

    return isAvailable && matchesSearch;
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Protected>
      <SideBar isKycVerified={true}>
        <div className="flex flex-col gap-5 md:gap-10">
          <div className="input flex items-center ml-4">
            <img src={searchIcon} alt={searchIcon} className="relative left-[30px] -ml-[30px]" />
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search for account"
              className="bg-[#F5F5F5] pl-[35px] rounded-[10px] h-[40px] lg:w-[400px] py-[16.21px]"
            />
          </div>

          <div className="flex flex-col gap-3 md:gap-5">
            <div className="flex justify-start">
              <h1 className="font-[900] text-[18px] md:text-[24px] leading-[40px] text-[#343434]">
                Accounts
              </h1>
            </div>
            <div
              className="flex gap-5 overflow-x-scroll scrollbar-hide"
              style={{
                WebkitOverflowScrolling: 'touch',
              }}
            >
              {availableCurrencies.map((currency) => (
                <CurrencyButton
                  key={currency.name}
                  currency={currency}
                  isActive={selectedCurrency === currency.name}
                  onClick={() => handleCurrencyChange(currency.name)}
                />
              ))}
            </div>
          </div>

          <div className="flex flex-col lg:flex-row justify-between gap-[20px]">
            <div className="flex flex-col gap-5 lg:w-[50%] lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px]">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3 font-[900] text-[14px] md:text-[16px] leading-[18px] text-[#343434]">
                  <img
                    src={getCurrencyIcon(selectedCurrency)}
                    alt={selectedCurrency}
                    className="md:w-[42px] md:h-[42px] w-[30px] h-[30px]"
                  />
                  <p>{selectedCurrency}</p>
                  <p>Account</p>
                </div>
                <button
                  className="flex items-center font-[500] text-[14px] leading-[20px]"
                  onClick={refreshWalletData}
                >
                  <img
                    src={refreshIcon}
                    alt={refreshIcon}
                    className={`transition-transform duration-500 ease-in-out ${
                      isRotated ? 'rotate-360' : ''
                    }`}
                  />
                  Refresh
                </button>
              </div>

              <div className="flex lg:flex-nowrap flex-wrap justify-between gap-4">
                <div className="flex items-center bg-[#F5F5F5] w-[100%] lg:w-[50%] h-[103px] rounded-[10px]">
                  {loadingWallet ? (
                    <div className="flex justify-center items-center w-full h-full">
                      <Spin size="small" />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center w-[100%]">
                      <p className="text-[#666666] font-[500] text-[10px] lg:text-[12px] leading-[16.2px]">
                        Available Balance
                      </p>
                      <h1 className="text-[#343434] font-[900] text-[12px] lg:text-[14px] leading-[40px]">
                        {walletData.available.toLocaleString()} {walletData.currency}
                      </h1>
                    </div>
                  )}
                </div>
                <div className="flex items-center bg-[#F5F5F5] w-[100%] lg:w-[50%] h-[103px] rounded-[10px]">
                  {loadingWallet ? (
                    <div className="flex justify-center items-center w-full h-full">
                      <Spin size="small" />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center w-[100%]">
                      <p className="text-[#666666] font-[500] text-[10px] lg:text-[12px] leading-[16.2px]">
                        Total Balance
                      </p>
                      <h1 className="text-[#343434] font-[900] text-[12px] lg:text-[14px] leading-[40px]">
                        {walletData.balance.toLocaleString()} {walletData.currency}
                      </h1>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                {selectedCurrency === 'NGN' && (
                  <Button
                    onClick={() => {
                      handleButtonClick('withdraw');
                      showModal('withdraw');
                    }}
                  >
                    Withdraw
                  </Button>
                )}
                <WithdrawModal
                  isOpen={!!modalState.withdraw}
                  handleCancel={() => handleCancel('withdraw')}
                  isAgent={false}
                  currency="NGN"
                />
              </div>
            </div>

            <div className="lg:w-[50%] lg:h-[269px] border-[0.5px] border-[#EAECF0] rounded-[10px] p-[15px] flex flex-col gap-2">
              <h1 className="font-[900] text-[18px] lg:text-[24px] leading-[40px] text-[#343434]">
                Quick Actions
              </h1>
              <div className="flex flex-col gap-2">
                <div className="flex flex-wrap gap-2">
                  <button
                    onClick={() => {
                      handleButtonClick('addFunds');
                      showModal('addFunds');
                    }}
                    className={`h-[78px] w-[100%] lg:w-[145px] rounded-[10px] ${
                      activeButton === 'addFunds' ? 'border border-[#1174ED]' : ''
                    } bg-[#F5F5F5] flex flex-col p-[6px]`}
                  >
                    <h1 className="font-[700] text-[14px] leading-[18.86px] text-[#343434]">
                      Add Funds
                    </h1>
                    <p className="text-[#666666] text-[12px] font-[500] leading-[16.2px]">
                      Fund your account with your local currency.
                    </p>
                  </button>
                  <AddFund
                    openModal={!!modalState.addFunds}
                    close={() => handleCancel('addFunds')}
                  />

                  <button
                    onClick={() => {
                      handleButtonClick('sendMoney');
                      showModal('sendMoney');
                    }}
                    className={`h-[78px] w-[100%] lg:w-[145px] rounded-[10px] ${
                      activeButton === 'sendMoney' ? 'border border-[#1174ED]' : ''
                    } bg-[#F5F5F5] flex flex-col p-[6px]`}
                  >
                    <h1 className="font-[700] text-[14px] leading-[18.86px] text-[#343434]">
                      Send Money
                    </h1>
                    <p className="text-[#666666] text-[12px] font-[500] leading-[16.2px]">
                      Pay out via our global network.
                    </p>
                  </button>
                  <SendMoney
                    openMoneyModal={!!modalState.sendMoney}
                    closeModaL={() => handleCancel('sendMoney')}
                  />

                  <button
                    onClick={() => {
                      handleButtonClick('convertPay');
                      showModal('convertPay');
                    }}
                    className={`h-[78px] w-[100%] lg:w-[145px] rounded-[10px] ${
                      activeButton === 'convertPay' ? 'border border-[#1174ED]' : ''
                    } bg-[#F5F5F5] flex flex-col p-[6px]`}
                  >
                    <h1 className="font-[700] text-[14px] leading-[18.86px] text-[#343434]">
                      Convert & Pay
                    </h1>
                    <p className="text-[#666666] text-[12px] font-[500] leading-[16.2px]">
                      Change to other currency and make payments.
                    </p>
                  </button>
                  <Convertion
                    isOpen={!!modalState.convertPay}
                    handleCancel={() => handleCancel('convertPay')}
                  />
                </div>
              </div>
            </div>
          </div>
          <AccountCheck />
          <AccountTable />
        </div>
      </SideBar>
    </Protected>
  );
};

export default Account;
